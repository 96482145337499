.cls-1,
.cls-2 {
  fill: none;
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 5px;
}
.cls-2 {
  stroke-linecap: round;
}
/*# sourceMappingURL=src/oapps/commons/pictos/humidity.css.map */